// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._VSrG6cAoRXHMPYMXBMQ{display:flex}`, "",{"version":3,"sources":["webpack://./src/sections/document-cards/file-extension-icon-display/file-extension-icon-display.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA","sourcesContent":[".root {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `_VSrG6cAoRXHMPYMXBMQ`
};
export default ___CSS_LOADER_EXPORT___;
