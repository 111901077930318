import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Loader, TextLink, TypographyComponent } from "gx-npm-ui";
import { DownloadIcon, ExclamationCircledIcon } from "gx-npm-icons";
import DocumentCheckboxSkip from "./components/checkbox-skip/document-card-checkbox-skip.component";
import { SurveyDocumentsAppContext } from "../../app.context";
import { FileUploadErrors, UserViews } from "../../app.types";
import styles from "./document-cardV2.styles.module.scss";
import { getAsyncRequest, UUID } from "gx-npm-lib";
import { useSyncElemHeight } from "../../lib/sync-height.lib";
import FileExtensionIconDisplayComponent from "./file-extension-icon-display/file-extension-icon-display.component";
import DocumentFileUploadV2 from "./document-card-file-upload-v2.component";

type DocumentCardNotUploadedProps = {
  id: UUID;
  index?: number;
  isSkipped: boolean;
  requestedDocName: string;
  attachedFileName: string;
  attachedFileId: UUID;
  attachedFileUploadedBy: string;
};

const DocumentCardNotUploadedV2 = ({
  attachedFileName,
  attachedFileId,
  attachedFileUploadedBy,
  id,
  index = 0,
  isSkipped,
  requestedDocName,
}: DocumentCardNotUploadedProps) => {
  const {
    docsUploaded,
    documents,
    setDocsUploaded,
    setDocuments,
    setShowErrorSnackBar,
    setShowSuccessSnackBar,
    userView,
    isPreviewMode,
    initProdId,
    initId,
  } = useContext(SurveyDocumentsAppContext);

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidFile, setIsValidFile] = useState<boolean | undefined>();
  const [attachedFileExt, setAttachedFileExt] = useState("");

  const handleDownloadAttachedFile = async () => {
    const url = `/api/v2/initiatives/${initId}/survey-recipients/${initProdId}/request-doc/${id}/file/${attachedFileId}/download`;
    try {
      const response = await getAsyncRequest(url);
      if (response.status !== 200 || !response.data?.data?.signedUrl) {
        throw new Error("Failed to retrieve the signed URL");
      }
      window.open(response.data.data.signedUrl, "_self");
    } catch (error) {
      setShowErrorSnackBar(true);
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const cardRef = useRef(null);

  useSyncElemHeight({ ref: cardRef, index });

  const handleClickUpload = () => {
    if (inputRef.current && !isPreviewMode) {
      inputRef.current.click();
    }
  };

  const handleOnError = (errorType: FileUploadErrors) => {
    if (errorType === FileUploadErrors.INVALID_FILE) {
      setIsValidFile(false);
    } else {
      setShowErrorSnackBar(true);
    }
  };

  const handleOnLoad = (loading: boolean) => {
    setIsLoading(loading);
    if (loading) {
      setIsValidFile(true);
    }
  };

  useEffect(() => {
    if (attachedFileName) {
      setAttachedFileExt(attachedFileName.split(".").pop()?.toLocaleLowerCase() || "");
    }
  }, [attachedFileName]);

  const handleSuccess = (data: { uploadedDocBy: string; uploadedDocDate: string; uploadedDocName: string }) => {
    const { uploadedDocBy, uploadedDocDate, uploadedDocName } = data;

    setDocsUploaded(docsUploaded + 1);

    setDocuments(
      documents.map((document) => {
        if (document.id === id) {
          return {
            ...document,
            uploadedDocBy,
            uploadedDocDate,
            uploadedDocName,
          };
        }
        return document;
      })
    );

    setShowSuccessSnackBar(true);
  };

  const renderNotUploaded = () => {
    if (isLoading) {
      return (
        <div aria-label={t("uploading document")} className={classNames(styles.uploadingLoader)}>
          <Loader rootClassName={styles.docLoader} />
          <TypographyComponent styling="p3" boldness={"medium"}>
            {t("Uploading your document")}
          </TypographyComponent>
        </div>
      );
    }
    if (isValidFile === false) {
      return (
        <Fragment>
          <div className={classNames(styles.validationFailedIcon)}>
            <ExclamationCircledIcon />
          </div>
          <div>
            <div className={classNames(styles.uploadText)}>
              <TypographyComponent rootClassName={classNames(styles.notUploadedTitle)}>
                {t("File upload failed")}
              </TypographyComponent>

              <TextLink
                onClick={handleClickUpload}
                rootClassName={classNames(styles.uploadLink)}
                text={t("choose another file")}
              />
            </div>
            <ul className={classNames(styles.errorChecklist)}>
              <li>
                <TypographyComponent rootClassName={classNames(styles.uploadedDocDesc)} styling="p4">
                  {t(
                    "Check your file type (.doc, .docx, .ppt, .pptx, .xls, .xlsx, .pdf, .txt, .mov, .mp4, .jpeg, .png)"
                  )}
                </TypographyComponent>
              </li>
              <li>
                <TypographyComponent rootClassName={classNames(styles.uploadedDocDesc)} styling="p4">
                  {t("Make sure your file is under 250MB")}
                </TypographyComponent>
              </li>
            </ul>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className={classNames(styles.uploadText)}>
          <TypographyComponent rootClassName={classNames(styles.notUploadedTitle)} styling={"p3"}>{`${t(
            "Drag and drop or"
          )} `}</TypographyComponent>

          <TextLink
            onClick={handleClickUpload}
            rootClassName={classNames(styles.uploadLink)}
            text={t("browse documents")}
          />
        </div>
        <TypographyComponent rootClassName={classNames(styles.uploadedDocDesc)} styling="p4">
          {t("Document types (.doc, .docx, .ppt, .pptx, .xls, .xlsx, .pdf, .txt, .mov, .mp4, .jpeg, .png)")}
        </TypographyComponent>
        <TypographyComponent rootClassName={classNames(styles.uploadedDocDesc)} styling="p4">
          {t("Max file size 250MB")}
        </TypographyComponent>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div ref={cardRef}>
        <TypographyComponent boldness="medium" rootClassName={styles.requestedDocName} styling="p2">
          {requestedDocName}
        </TypographyComponent>
      </div>

      <TypographyComponent
        rootClassName={styles.requestedDocCompany}
        color={"iron"}
        styling={"p4"}
        boldness={"regular"}
      >
        {t("Document provided by ")}
        {attachedFileUploadedBy}
      </TypographyComponent>
      {attachedFileName ? (
        <div
          className={styles.filePill}
          onClick={() => handleDownloadAttachedFile()}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === "Enter" && handleDownloadAttachedFile()}
          style={{ cursor: "pointer" }}
        >
          <FileExtensionIconDisplayComponent fileExt={attachedFileExt} />
          <TypographyComponent color={"carbon"} rootClassName={styles.fileName} styling={"p3"} boldness={"regular"}>
            {attachedFileName}
          </TypographyComponent>
          <DownloadIcon />
        </div>
      ) : (
        <div className={styles.noPillDash}>
          <TypographyComponent color={"iron"} styling={"p4"} boldness={"regular"}>
            {t("—")}
          </TypographyComponent>
        </div>
      )}

      {userView === UserViews.RECIPIENT ? (
        <Fragment>
          <DocumentFileUploadV2
            id={id}
            onError={handleOnError}
            onLoad={handleOnLoad}
            onSuccess={handleSuccess}
            ref={inputRef}
          >
            <div
              className={classNames(
                styles.docCardInner,
                isValidFile === false ? styles.validationFailed : styles.notUploaded
              )}
            >
              {renderNotUploaded()}
            </div>
          </DocumentFileUploadV2>
          <DocumentCheckboxSkip id={id} isSkipped={isSkipped} />
        </Fragment>
      ) : (
        <Fragment>
          <div className={classNames(styles.docCardInner, styles.notUploadedEvaluator)}>
            <TypographyComponent boldness="medium" rootClassName={classNames(styles.notUploadedDocName)} styling="p4">
              {t("Document not uploaded yet")}
            </TypographyComponent>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DocumentCardNotUploadedV2;
