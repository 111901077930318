import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { SnackbarBanner, TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import DocumentCard from "./document-card.component";
import styles from "./document-cards.styles.module.scss";
import { SurveyDocumentsAppContext } from "../../app.context";
import { useContext } from "react";
import { GCOM_4101__docManagementV2 } from "../../lib/feature-flag";
import DocumentCardV2 from "./document-card-v2.component";

const DocumentCardsSection = () => {
  const { t } = useTranslation();
  const {
    docsTotal,
    docsUploaded,
    documents,
    setShowErrorSnackBar,
    setShowSuccessSnackBar,
    showErrorSnackBar,
    showSuccessSnackBar,
    userView,
  } = useContext(SurveyDocumentsAppContext);
  const ffGCOM4104 = useFeatureFlag(GCOM_4101__docManagementV2);

  return (
    <div className={classNames(styles.docsSectionContainer)}>
      <div className={classNames(styles.titleContainer)}>
        <TypographyComponent styling={"p3"} boldness={"medium"} color={"iron"}>
          {t("Documents uploaded")}
        </TypographyComponent>
        <TypographyComponent styling={"h5"} rootClassName={classNames(styles.docsUploaded)}>
          {docsUploaded} / {docsTotal}
        </TypographyComponent>
      </div>
      <div className={classNames(styles.cardsContainer)}>
        {documents.map((doc, index) => {
          const isUploaded = !doc.isSkipped && !!doc.uploadedDocName;

          return ffGCOM4104 ? (
            <DocumentCardV2
              id={doc.id}
              index={index}
              isSkipped={doc.isSkipped}
              isUploaded={isUploaded}
              key={doc.id}
              requestedDocName={doc.requestedDocName}
              uploadedDocBy={doc.uploadedDocBy}
              uploadedDocDate={doc.uploadedDocDate}
              uploadedDocName={doc.uploadedDocName}
              attachedFileName={doc.attachedFileName}
              attachedFileId={doc.attachedFileId}
              attachedFileUploadedBy={doc.attachedFileUploadedBy}
            />
          ) : (
            <DocumentCard
              id={doc.id}
              index={index}
              isSkipped={doc.isSkipped}
              isUploaded={isUploaded}
              key={doc.id}
              requestedDocName={doc.requestedDocName}
              uploadedDocBy={doc.uploadedDocBy}
              uploadedDocDate={doc.uploadedDocDate}
              uploadedDocName={doc.uploadedDocName}
              userView={userView}
            />
          );
        })}
      </div>
      <SnackbarBanner
        isOpen={showErrorSnackBar}
        setIsOpen={setShowErrorSnackBar}
        type="ERROR"
        isDefaultErrorMessage={true}
      />
      <SnackbarBanner
        isOpen={showSuccessSnackBar}
        message={t("Your document was successfully added.")}
        setIsOpen={() => setShowSuccessSnackBar(false)}
        type="SUCCESS"
      />
    </div>
  );
};

export default DocumentCardsSection;
