import React, { Fragment, KeyboardEvent, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAsyncRequest, isValidDate, useCaptureEventsV2, UUID } from "gx-npm-lib";
import { DownloadIcon } from "gx-npm-icons";
import { TypographyComponent } from "gx-npm-ui";
import { downloadSurveyDocument } from "../../lib/requests.lib";
import { SurveyDocumentsAppContext } from "../../app.context";
import styles from "./document-cardV2.styles.module.scss";
import FileExtensionIconDisplayComponent from "./file-extension-icon-display/file-extension-icon-display.component";
import { UserViews } from "../../app.types";
import DocumentCheckboxSkip from "./components/checkbox-skip/document-card-checkbox-skip.component";
import classNames from "classnames";
import DocumentButtonDelete from "./components/button-delete/document-card-button-delete.component";
import { useSyncElemHeight } from "../../lib/sync-height.lib";
import { ClientEvent } from "../../app.contants";
import { IconCheckMarkComplete } from "../../ui/icons/document.icons";

type DocumentCardUploadedProps = {
  id: UUID;
  index?: number;
  isSkipped: boolean;
  requestedDocName: string;
  uploadedDocBy: string;
  uploadedDocDate: string;
  uploadedDocName: string;
  attachedFileName: string;
  attachedFileId: UUID;
  attachedFileUploadedBy: string;
};

const DocumentCardUploadedV2 = ({
  id,
  index = 0,
  isSkipped,
  requestedDocName,
  uploadedDocBy,
  uploadedDocDate,
  uploadedDocName,
  attachedFileName,
  attachedFileId,
  attachedFileUploadedBy,
}: DocumentCardUploadedProps) => {
  const { t } = useTranslation();
  const [uploadedDocHover, setUploadedDocHover] = useState(false);
  const [attachedFileExtenstion, setAttachedFileExtenstion] = useState("");
  const { initId, initProdId, setShowErrorSnackBar, surveyId, userView } = useContext(SurveyDocumentsAppContext);
  const cardRef = useRef(null);
  const captureEvents = useCaptureEventsV2();

  const handleDownloadAttachedFile = async () => {
    const url = `/api/v2/initiatives/${initId}/survey-recipients/${initProdId}/request-doc/${id}/file/${attachedFileId}/download`;
    try {
      const response = await getAsyncRequest(url);
      if (response.status !== 200 || !response.data?.data?.signedUrl) {
        throw new Error("Failed to retrieve the signed URL");
      }
      window.open(response.data.data.signedUrl, "_self");
    } catch (error) {
      setShowErrorSnackBar(true);
    }
  };

  useSyncElemHeight({ ref: cardRef, index });

  const handleClickDownload = () => {
    downloadSurveyDocument(
      { docId: id, initId, initProdId, surveyId, userView },
      (data) => {
        window.open(data.signedUrl, "_self");
        const metaData = { initiativeId: initId, initProductId: initProdId, requestedDocId: id, surveyId };
        captureEvents([{ eventType: ClientEvent.SURVEY_DOCUMENTS_DOWNLOAD_REQ_DOC_ATTACHED_FILE, metaData }]);
      },
      () => {
        setShowErrorSnackBar(true);
      }
    );
  };

  const handleKeyDownDownload = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleClickDownload();
    }
  };

  const formatDate = (date: string) => {
    let formattedDate = "";
    if (isValidDate(date)) {
      formattedDate = new Date(date)
        .toLocaleString("en-us", {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        .replace(" at ", ", ");
    }
    return formattedDate;
  };

  useEffect(() => {
    if (attachedFileName) {
      setAttachedFileExtenstion(attachedFileName.split(".").pop()?.toLocaleLowerCase() || "");
    }
  }, [attachedFileName]);

  const uploadedDocParts = uploadedDocName.split(".");
  const uploadedDocExt = uploadedDocParts[uploadedDocParts.length - 1].toLocaleLowerCase();

  return (
    <Fragment>
      <TypographyComponent boldness="medium" rootClassName={styles.requestedDocName} styling="p2">
        {requestedDocName}
      </TypographyComponent>
      <TypographyComponent
        rootClassName={styles.requestedDocCompany}
        color={"iron"}
        styling={"p4"}
        boldness={"regular"}
      >
        {t("Document provided by ")}
        {attachedFileUploadedBy}
      </TypographyComponent>
      {attachedFileName ? (
        <div
          className={styles.filePill}
          onClick={() => handleDownloadAttachedFile()}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === "Enter" && handleDownloadAttachedFile()}
          style={{ cursor: "pointer" }}
        >
          <FileExtensionIconDisplayComponent fileExt={attachedFileExtenstion} />
          <TypographyComponent color={"carbon"} rootClassName={styles.fileName} styling={"p3"} boldness={"regular"}>
            {attachedFileName}
          </TypographyComponent>
          <DownloadIcon />
        </div>
      ) : (
        <div className={styles.noPillDash}>
          <TypographyComponent color={"iron"} styling={"p4"} boldness={"regular"}>
            {t("—")}
          </TypographyComponent>
        </div>
      )}

      <div
        aria-label={t("download document")}
        className={classNames(styles.docCardInner, styles.uploadedDoc)}
        onClick={handleClickDownload}
        onKeyDown={handleKeyDownDownload}
        onFocus={() => setUploadedDocHover(true)}
        onMouseOver={() => setUploadedDocHover(true)}
        onMouseLeave={() => setUploadedDocHover(false)}
        role="button"
        tabIndex={0}
      >
        <div className={classNames(styles.uploadedDocComplete)}>
          <IconCheckMarkComplete />
        </div>
        <div className={classNames(styles.uploadedDocWrapper)}>
          <div className={classNames(styles.uploadedDocType)}>
            <FileExtensionIconDisplayComponent fileExt={uploadedDocExt} />
          </div>
          <div>
            <TypographyComponent boldness="medium" styling={"p3"} rootClassName={styles.uploadedDocName}>
              {uploadedDocName}
            </TypographyComponent>
            <TypographyComponent rootClassName={classNames(styles.uploadedDocDate)} styling="p4">
              {formatDate(uploadedDocDate)}
            </TypographyComponent>
            <TypographyComponent rootClassName={classNames(styles.uploadedDocBy)} styling="p4">
              {t("by")}
              {uploadedDocBy}
            </TypographyComponent>
          </div>
          {uploadedDocHover && userView === UserViews.RECIPIENT && <DocumentButtonDelete id={id} />}
        </div>
      </div>
      {userView === UserViews.RECIPIENT && <DocumentCheckboxSkip id={id} isSkipped={isSkipped} />}
    </Fragment>
  );
};

export default DocumentCardUploadedV2;
