import classNames from "classnames";
import styles from "./document-card.styles.module.scss";
import { UUID } from "gx-npm-lib";
import DocumentCardUploadedV2 from "./document-card-uploaded-v2.component";
import DocumentCardNotUploadedV2 from "./document-card-not-uploaded-v2.component";
import DocumentCardSkipV2 from "./document-card-skip-v2.component";
import React from "react";

type DocumentCardV2Props = {
  id: UUID;
  index?: number;
  isSkipped: boolean;
  isUploaded: boolean;
  requestedDocName: string;
  uploadedDocBy: string;
  uploadedDocDate: string;
  uploadedDocName: string;
  attachedFileName: string;
  attachedFileId: UUID;
  attachedFileUploadedBy: string;
};

const DocumentCardV2 = ({
  id,
  index,
  isSkipped = false,
  isUploaded = false,
  requestedDocName,
  uploadedDocBy,
  uploadedDocDate,
  uploadedDocName,
  attachedFileName,
  attachedFileId,
  attachedFileUploadedBy,
}: DocumentCardV2Props) => {
  return (
    <div className={classNames(styles.docCardV2, isSkipped && styles.skipped)}>
      <div>
        {isSkipped && (
          <DocumentCardSkipV2 id={id} index={index} isSkipped={isSkipped} requestedDocName={requestedDocName} />
        )}
        {!isSkipped && isUploaded && (
          <DocumentCardUploadedV2
            id={id}
            index={index}
            isSkipped={isSkipped}
            requestedDocName={requestedDocName}
            uploadedDocBy={uploadedDocBy}
            uploadedDocDate={uploadedDocDate}
            uploadedDocName={uploadedDocName}
            attachedFileName={attachedFileName}
            attachedFileId={attachedFileId}
            attachedFileUploadedBy={attachedFileUploadedBy}
          />
        )}
        {!isSkipped && !isUploaded && (
          <DocumentCardNotUploadedV2
            attachedFileName={attachedFileName}
            attachedFileId={attachedFileId}
            attachedFileUploadedBy={attachedFileUploadedBy}
            id={id}
            index={index}
            isSkipped={isSkipped}
            requestedDocName={requestedDocName}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentCardV2;
