import { Fragment, useRef } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import DocumentCheckboxSkip from "./components/checkbox-skip/document-card-checkbox-skip.component";
import { TypographyComponent } from "gx-npm-ui";
import { UserViewTypes } from "../../app.types";
import styles from "./document-cardV2.styles.module.scss";
import { UUID } from "gx-npm-lib";
import { useSyncElemHeight } from "../../lib/sync-height.lib";

type DocumentCardSkipProps = {
  id: UUID;
  index?: number;
  isSkipped: boolean;
  requestedDocName: string;
  userView?: UserViewTypes;
};

const DocumentCardSkipV2 = ({ id, index = 0, isSkipped, requestedDocName }: DocumentCardSkipProps) => {
  const { t } = useTranslation();
  const cardRef = useRef(null);

  useSyncElemHeight({ ref: cardRef, index });

  return (
    <Fragment>
      <div ref={cardRef}>
        <TypographyComponent boldness="medium" rootClassName={styles.requestedDocName} styling="p2">
          {requestedDocName}
        </TypographyComponent>
      </div>
      <div className={classNames(styles.docCardInner, styles.skipped)}>
        <div className={classNames(styles.skippedDivider)} />
        <TypographyComponent
          color={"coal"}
          boldness="medium"
          rootClassName={classNames(styles.skippedDocName)}
          styling="p2"
        >
          {t("This document has been skipped.")}
        </TypographyComponent>
        <TypographyComponent rootClassName={classNames(styles.uploadedDocDesc)} styling="p4">
          {t("To edit your response and upload a document, uncheck the box below.")}
        </TypographyComponent>
      </div>
      <DocumentCheckboxSkip id={id} isSkipped={isSkipped} />
    </Fragment>
  );
};

export default DocumentCardSkipV2;
