import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { colorPalette } from "gx-npm-common-styles";
import { defaultToEmptyString, postAsyncRequest, putAsyncRequest, UUID } from "gx-npm-lib";
import { SurveyDocumentsAppContext } from "../../app.context";
import { FileUploadErrors } from "../../app.types";

const FILE_SIZE_LIMIT = 250000000;

const acceptedFileTypes = [
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/plain",
  "video/quicktime",
  "video/mp4",
  "image/jpeg",
  "image/png",
];

const colorLightBerry = colorPalette.interactions.lightBerry.hex;
const colorUnset = "unset";

type FileUploadProps = {
  children?: Array<ReactNode> | ReactNode | number | string;
  id: UUID;
  onError?: (error: FileUploadErrors) => void;
  onLoad?: (loading: boolean) => void;
  onSuccess?: (data: object) => void;
};

const DocumentFileUploadV2 = React.forwardRef<HTMLInputElement, FileUploadProps>((props, ref) => {
  const { t } = useTranslation();
  const { initId, initProdId, surveyId } = useContext(SurveyDocumentsAppContext);
  const { children, id, onError, onLoad, onSuccess } = props;

  const [backgroundColor, setBackgroundColor] = useState<string>();

  const dropRef = useRef<HTMLDivElement>(null);

  const handleFileUpload = useCallback(
    async (fileObj: File) => {
      const filename = defaultToEmptyString(fileObj.name);

      if (!filename || !fileObj || fileObj.size > FILE_SIZE_LIMIT || acceptedFileTypes.indexOf(fileObj.type) === -1) {
        onError?.(FileUploadErrors.INVALID_FILE);
        return;
      }

      onLoad?.(true);

      const url = `/api/v2/initiatives/${initId}/survey-documents/recipient/${surveyId}/${initProdId}/documents/${id}/upload`;
      const postResponse = await postAsyncRequest(url, { filename });

      if (postResponse.status !== 201 || !postResponse.data?.data?.signedUrl) {
        onLoad?.(false);
        onError?.(FileUploadErrors.UPLOAD_POST_ERROR);
        return;
      }

      const { signedUrl, uploadedDocBy, uploadedDocDate } = postResponse.data.data;
      try {
        let blobData = null;
        const { type } = fileObj;
        const reader = new FileReader();

        reader.onload = async (progressEvent) => {
          const isValidEvent = typeof progressEvent?.target?.result === "string";
          const binary = window.atob(isValidEvent ? progressEvent.target.result.split(",")[1] : "");

          const array = [];

          for (let idx = 0; idx < binary.length; idx++) {
            array.push(binary.charCodeAt(idx));
          }

          blobData = new Blob([new Uint8Array(array)], { type });
          const requestConfig = { headers: { "Content-Type": type } };
          const putResponse = await putAsyncRequest(signedUrl, blobData, requestConfig);

          if (putResponse?.status === 200) {
            onSuccess?.({ uploadedDocBy, uploadedDocDate, uploadedDocName: filename });
          } else {
            onError?.(FileUploadErrors.UPLOAD_PUT_ERROR);
          }
          onLoad?.(false);
        };
        reader.readAsDataURL(fileObj);
      } catch (error) {
        onLoad?.(false);
        onError?.(FileUploadErrors.UNEXPECTED_ERROR);
      }
    },
    [id, initId, initProdId, surveyId, onError, onLoad, onSuccess]
  );

  const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;
    const fileObj = files?.[0];
    if (fileObj) {
      handleFileUpload(fileObj);
    }
  };

  useEffect(() => {
    let dragDropEnterLeaveCounter = 0;

    const handleDragEnter = () => {
      dragDropEnterLeaveCounter = dragDropEnterLeaveCounter + 1;
      setBackgroundColor(colorLightBerry);
    };
    const handleDragLeave = () => {
      dragDropEnterLeaveCounter = dragDropEnterLeaveCounter - 1;
      if (dragDropEnterLeaveCounter === 0) {
        setBackgroundColor(colorUnset);
      }
    };
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragDropEnterLeaveCounter = 0;
      setBackgroundColor(colorUnset);

      const fileObj = e.dataTransfer?.files?.[0];
      if (fileObj) {
        handleFileUpload(fileObj);
      }
    };

    const dragCurrRef = dropRef.current;
    if (dragCurrRef) {
      dragCurrRef.addEventListener("dragenter", handleDragEnter);
      dragCurrRef.addEventListener("dragleave", handleDragLeave);
      dragCurrRef.addEventListener("dragover", handleDragOver);
      dragCurrRef.addEventListener("drop", handleDrop);
    }
    return () => {
      if (dragCurrRef) {
        dragCurrRef.removeEventListener("dragenter", handleDragEnter);
        dragCurrRef.removeEventListener("dragleave", handleDragLeave);
        dragCurrRef.removeEventListener("dragover", handleDragOver);
        dragCurrRef.removeEventListener("drop", handleDrop);
      }
    };
  }, [handleFileUpload]);

  return (
    <div aria-label={t("file drop area")} ref={dropRef} style={{ backgroundColor }}>
      {children}
      <input
        accept=".pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.txt,.mov,.mp4,.jpeg,.png"
        onChange={handleFileChange}
        ref={ref}
        style={{ display: "none" }}
        type="file"
      />
    </div>
  );
});

export default DocumentFileUploadV2;
